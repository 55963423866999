/* フッター部 */
footer{
    max-width: 1500px;
    margin-left:auto;
    margin-right:auto;
    background-color: #0f62c1;
    background-repeat: repeat;
 /*    background-color: #1F6BFF;*/
    color: white;
}
.text-dark-blue{
    color: #1F6BFF;
}
#Footer{
    max-width: 1000px;
}
#Footer a{
    color: white;
}
footer .badge{
    font-size: 11.5px;
    padding-top: 6px;
    padding-bottom: 3px;
    padding-left: 8px;
    padding-right: 8px;
}
.footer_tel{
    font-weight: 600;
    font-size: 30px;
}
.footer_fax{
    font-weight: 600;
    font-size: 20px;
}
footer ul{
    list-style: none;
    padding: 0;
    font-size:13px;
    margin-bottom: 0;
}
footer li{
    position: relative;
    padding-bottom: 10px;
    padding-left: 10px;
    margin-left: 10px;
}

footer li::before{
    position: absolute;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\F0DA";
    top: 0;
    left: -10px;
}

footer li a{
    color:white;
}

/* パッケージ2列目の設定 */
footer .noicon::before{
    position: absolute;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: none;
    top: 0;
    left: -10px;
}
footer .ptop{
    @media (max-width: 767.98px) {
        display: none!important;
    }
}
footer .package-pb{
    padding-top: 10px;
    @media (max-width: 767.98px) {
        padding-top: 0;
    }
}

/* フッター最下部 */
.copyright{
    text-align: center;
    background-color: #1e3b7e;
    height: 40px;
    font-size: 12px;
}

/* col-md 991px以下 */
@media screen and (max-width: 991px) {
    /* フッター部 */
    .copyright{
        font-size: 13px;
    }
}
/* col-sm 767px以下 */
@media screen and (max-width: 767px){
    /* フッター部 */
    .footer > .menu{
        display: inline-block;
        text-align: left;
    }
    .copyright{
        font-size: 11px;
    }
}